import React, { useEffect } from "react";
import { useRouteError, useNavigate } from "react-router-dom";
import MyCircularProgressLabel from "./MyCircularProgressLabel";

const ErrorBoundary = () => {
  const navigate = useNavigate();
  let error = useRouteError();

  // Uncaught ReferenceError: path is not defined

  useEffect(() => {
    console.log("ErrorBoundary: ", error);
    navigate(-1);
  }, []);
  return <MyCircularProgressLabel label="Cheking" />;
};

export default ErrorBoundary;
