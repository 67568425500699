import { createTheme } from "@mui/material/styles";

export const MyTheme = createTheme({
  typography: {
    fontFamily: [
      "Gotham light",
      "Helvetica",
      "Arial",
      "Lucida",
      "sans-serif",
    ].join(","),
    h3: {
      fontWeight: 700,
      fontSize: 32,
      lineHeight: "137%",
    },
    h4: {
      fontWeight: 700,
      fontSize: 32,
      lineHeight: "137%",
      color: "#615D59",
    },
    h6: {
      color: "#615D59",
    },
    k2: {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: "32px",
    },
    k3: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: "137%",
    },
    k4: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "137%",
    },
  },
  palette: {
    primary: {
      //light: "#FF7423",
      main: "#00cfb5",
      ///dark: "#002884",
    },
    secondary: {
      //light: "#ff7961",
      main: "#8E8884",
      //dark: "#ba000d",
      contrastText: "#FFFFFF",
    },
    stateError: {
      //light: "#ff7961",
      main: "#DE3B3B",
      //dark: "#ba000d",
      contrastText: "#FFFFFF",
    },
    stateInfo: {
      //light: "#ff7961",
      main: "#297EFF",
      //dark: "#ba000d",
      contrastText: "#FFFFFF",
    },
    stateSuccess: {
      //light: "#ff7961",
      main: "#34C759",
      //dark: "#ba000d",
      contrastText: "#FFFFFF",
    },
    greyColor: {
      //light: "#ff7961",
      main: "#E1E1E3",
      greyC: "#E1E1E3",
      //dark: "#ba000d",
      contrastText: "#FFFFFF",
    },
  },
});
