import React from "react";
import {
  Box,
  Button,
  List,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";
import chatBotImg from "../../img/chatbot.png";
import bscreenImg from "../../img/bscreen1.png";
import { useAppData } from "../../context/AppContext";
import BoxAttributes from "../generic/BoxAttributes";

export default function LayoutBScreen() {
  const { isMovile } = useAppData();
  const attributesData = [
    {
      title: "Aliados (Partners)",
      itemsText: [
        "Personas y empresas con pantallas digitales",
        "disponibles en locales comerciales",
        "(Televisor, pantallas de exterior, video Beam…)",
        "vallas publicitarias y/o eventos",
      ],
    },
    {
      title: "Usuarios",
      itemsText: [
        "Personas y empresas con oferta",
        "de productos y servicios",
        "que desean promocionarlos.",
      ],
    },
    {
      title: "Usuarios",
      itemsText: [
        "Personas y empresas",
        "que desean compartir sus experiencias",
        "con los demás",
      ],
    },
  ];
  const benefitsData = [
    "Plataforma tecnológica disponible en la web",
    "Conectar espacios publicitarios con Anunciantes",
    "Diversificar la publicidad. Publicidad al alcance de todos",
    "Simplifica tu modelo operativo de publicidad",
    "Ser parte del primer market place de pantallas publicitarias",
    "No requiere inversiones extras de dinero",
    "Publicaciones automáticas, fácil y en línea.",
    "Maximizar ingresos y Monetizar recursos ociosos",
  ];
  const stepsPartners = [
    { primary: "Crea tú cuenta gratis en nuestra herramienta B-Screen" },
    { primary: "Adiciona tus pantallas a B-Screen y sus características" },
    { primary: "Configura tus horarios disponibles" },
    { primary: "Configura precios y restricción de contenido" },
    { primary: "Sube tus videos base a la plataforma y listo!" },
  ];
  const stepsUsers = [
    { primary: "Crea tú cuenta gratis en nuestra herramienta B-Screen" },
    {
      primary: "Recarga saldo para comprar spots publicitarios",

      secundary: "Se pueden comprar spots desde 15 segundos",
    },
    {
      primary: "Acepta las políticas y Sube tus videos a la plataforma gratis",
      secundary: "B-screen valida y clasifica el contenido del video*",
    },
    {
      primary: "Selecciona las pantallas en las cuales deseas publicar",
      secundary: "Puedes programar tú video en horario especifico o prime",
    },
    {
      primary:
        "Envía tú orden y listo! Tú anuncio quedará programado y publicado",
    },
  ];
  const handleOpenBScreen = () => {
    //console.log("handleopenWhatsapp: ");
    let url = `https://bscreen.web.app/`;
    console.log("url: ", url);
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ typography: "h4", fontWeight: "bold" }}>BOTTA Screen</Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
          direction={{ xs: "column", sm: "row", xl: "row", md: "row" }}
        >
          <img src={chatBotImg} width={380} />
          <Stack spacing={0.5}>
            <Box sx={{ typography: "subtitle1" }}>
              Conectamos Pantallas Publicitarias con Anunciantes en tiempo real
            </Box>
            <Box sx={{ typography: "subtitle1" }}>
              Bienvenidos al market place de Pantallas Publicitarias
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Box sx={{ typography: "h4", fontWeight: "bold", marginTop: 1 }}>
        ¿Para quién es Botta Screen?
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {attributesData?.map((attributesData, i) => (
          <BoxAttributes attributesData={attributesData} />
        ))}
      </Box>
      <Box sx={{ typography: "h4", fontWeight: "bold", marginTop: 2 }}>
        ¿Qué ofrece Botta Screen?
      </Box>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {benefitsData?.map((benefit, i) => (
          <ListItem key={i}>
            <ListItemAvatar>
              <TaskAltIcon color="primary" />
            </ListItemAvatar>
            <ListItemText primary={benefit} />
          </ListItem>
        ))}
      </List>
      <Box sx={{ typography: "h4", fontWeight: "bold", marginTop: 2 }}>
        ¿Cómo te sumas al catalogo como aliado?
      </Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
          direction={{ xs: "column", sm: "row", xl: "row", md: "row" }}
        >
          <img src={bscreenImg} width={380} />
          <Stack spacing={0.5}>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              {stepsPartners?.map((step, i) => (
                <ListItem key={i}>
                  <ListItemAvatar>
                    {i === 0 ? (
                      <LooksOneIcon color="primary" />
                    ) : i === 1 ? (
                      <LooksTwoIcon color="primary" />
                    ) : i === 2 ? (
                      <Looks3Icon color="primary" />
                    ) : i === 3 ? (
                      <Looks4Icon color="primary" />
                    ) : i === 4 ? (
                      <Looks5Icon color="primary" />
                    ) : (
                      <TaskAltIcon color="primary" />
                    )}
                  </ListItemAvatar>
                  <ListItemText primary={step.primary} />
                </ListItem>
              ))}
            </List>
          </Stack>
        </Stack>
      </Box>
      <Box sx={{ typography: "h4", fontWeight: "bold", marginTop: 2 }}>
        ¿Cómo publicas tus videos?
      </Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
          direction={{ xs: "column", sm: "row", xl: "row", md: "row" }}
        >
          <img src={bscreenImg} width={380} />
          <Stack spacing={0.5}>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              {stepsUsers?.map((step, i) => (
                <ListItem key={i}>
                  <ListItemAvatar>
                    {i === 0 ? (
                      <LooksOneIcon color="primary" />
                    ) : i === 1 ? (
                      <LooksTwoIcon color="primary" />
                    ) : i === 2 ? (
                      <Looks3Icon color="primary" />
                    ) : i === 3 ? (
                      <Looks4Icon color="primary" />
                    ) : i === 4 ? (
                      <Looks5Icon color="primary" />
                    ) : (
                      <TaskAltIcon color="primary" />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={step.primary}
                    secondary={step.secundary}
                  />
                </ListItem>
              ))}
            </List>
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          typography: "h4",
          marginTop: 3,
          marginBottom: 2,
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        Ingresa a B-SCREEN
      </Box>
      <Button
        variant="contained"
        fullWidth
        size="large"
        onClick={handleOpenBScreen}
      >
        Iniciar
      </Button>
    </Box>
  );
}
