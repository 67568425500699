import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, IconButton, Stack } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import LoyaltyIcon from "@mui/icons-material/Loyalty";

function BoxAttributes({ attributesData }) {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 350,
        margin: 1,
        boxShadow: 2,
        borderRadius: 1,
        backgroundColor: "#F2F2F2",
      }}
    >
      <Stack justifyContent="center" alignItems="center" spacing={0.5}>
        <IconButton color="primary">
          <LoyaltyIcon />
        </IconButton>
        <Box sx={{ typography: "h6", marginRight: 1, fontWeight: "bold" }}>
          {attributesData?.title}
        </Box>
        {attributesData?.itemsText?.map((item, i) => (
          <Box sx={{ typography: "body1", marginRight: 1 }}>{item}</Box>
        ))}
        <Box sx={{ typography: "body1", marginTop: 2 }}></Box>
      </Stack>
    </Box>
  );
}

export default BoxAttributes;
