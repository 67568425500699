import React from "react";
import { Box, Stack } from "@mui/material";
import usChatImg from "../../img/usChat.png";
import valuesImg from "../../img/values.png";
import helpersImg from "../../img/helpers.png";
import { useAppData } from "../../context/AppContext";
import BoxAttributes from "../generic/BoxAttributes";

export default function LayoutCompanyInformation() {
  const { isMovile } = useAppData();
  const attributesData = [
    {
      title: "Misión",
      itemsText: [
        "Mejoramos la atención al cliente de nuestros clientes con servicios de alta calidad, adaptables y a costos competitivos, excelencia para nuestros clientes y excelencia laboral para nuestros empleados.",
      ],
    },
    {
      title: "Visión",
      itemsText: [
        "Ser los preferidos por los clientes y la solución más importante para atender tus clientes cuando y donde quieras.",
      ],
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
          direction={{ xs: "column", sm: "row", xl: "row", md: "row" }}
        >
          <img src={usChatImg} width={380} />
          <Stack spacing={0.5}>
            <Box sx={{ typography: "subtitle1" }}>
              BOTTA es una compañía Colombiana que centra sus esfuerzos en
              ayudar a los empresarios a evolucionar la forma en la que se
              comunican con sus clientes, aplicando tecnología de punta para
              responder a las exigencias de un mercado cada vez más demandante.
            </Box>
            <Box sx={{ typography: "subtitle1" }}>
              Sabemos que es vital que te enfoques en las actividades que más
              generan valor para tu negocio, por esta razón nos esforzamos día a
              día en generar soluciones que te asistan en la entrega de
              información, toma de pedidos y gestión de citas para tu negocio.
            </Box>
            <Box sx={{ typography: "subtitle1" }}>
              Somos una compañía tecnológica orientada a brindarte la mejor
              experiencia a ti y a tus clientes, permitiendo que delegues
              actividades rutinarias y te enfoques en lo que más importa,
              destacar en un mercado cada vez más competitivo.
            </Box>
            <Box sx={{ typography: "subtitle1" }}>
              BOTTA realiza todos sus procesos con los canales digitales
              acatando las políticas de cada una de estas plataformas para
              brindarte un servicio seguro.
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Box sx={{ typography: "h4", fontWeight: "bold", marginTop: 1 }}></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {attributesData?.map((attributesData, i) => (
          <BoxAttributes attributesData={attributesData} />
        ))}
      </Box>
      <Box sx={{ typography: "h4", fontWeight: "bold", marginTop: 2 }}>
        Estos valores nos representan
      </Box>
      <img src={valuesImg} width={"100%"} />
      <Box sx={{ typography: "h4", fontWeight: "bold", marginTop: 2 }}>
        Acompañados por:
      </Box>
      <img src={helpersImg} width={"100%"} />
    </Box>
  );
}
