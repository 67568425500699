import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";

const AppContext = createContext(null);

export function AppProvider(props) {
  const [isMovile, setIsMovile] = useState(null);

  useEffect(() => {
    //console.log("window.innerWidth: ", window.innerWidth);
    if (window.innerWidth <= 772) {
      setIsMovile(true);
    } else {
      setIsMovile(false);
    }
  }, [isMovile]);

  const value = useMemo(() => {
    return {
      isMovile,
    };
  }, [isMovile]);

  return <AppContext.Provider value={value} {...props} />;
}

export function useAppData() {
  const context = useContext(AppContext);
  return context;
}
