import { router } from "./MyRouter";
import { RouterProvider } from "react-router-dom";
import { AppProvider } from "./context/AppContext";
import { ThemeProvider } from "@mui/material/styles";
import { MyTheme } from "./config/MyTheme";

function App() {
  return (
    <ThemeProvider theme={MyTheme}>
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
