import { createBrowserRouter } from "react-router-dom";
import ErrorBoundary from "./components/generic/ErrorBoundary ";
import LayoutComponent from "./components/layout/LayoutComponent";
import LayoutOmnichannel from "./components/layout/LayoutOmnichannel";
import LayoutChatBot from "./components/layout/LayoutChatBot";
import LayoutCompanyInformation from "./components/layout/LayoutCompanyInformation";
import LayoutContactUs from "./components/layout/LayoutContactUs";
import LayoutBScreen from "./components/layout/LayoutBScreen";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LayoutComponent />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <LayoutBScreen />,
      },
      {
        path: "/bscreens",
        element: <LayoutBScreen />,
      },
      {
        path: "/omnichannel",
        element: <LayoutOmnichannel />,
      },
      {
        path: "/chatbots",
        element: <LayoutChatBot />,
      },
      {
        path: "/company",
        element: <LayoutCompanyInformation />,
      },
      {
        path: "/contactUs",
        element: <LayoutContactUs />,
      },
    ],
  },
]);
