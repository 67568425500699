import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import FeedIcon from "@mui/icons-material/Feed";
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import CategoryIcon from "@mui/icons-material/Category";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";

function MyMenuBotton() {
  const [menuOption, setMenuOption] = useState("bscreens");
  const navigate = useNavigate();

  const handleNavigation = (event, newValue) => {
    event.preventDefault();
    //console.log("newValue: ", newValue);
    setMenuOption(newValue);
    switch (newValue) {
      case "bscreens":
        navigate(`/bscreens`);
        break;
      case "omnichannel":
        navigate(`/omnichannel`);
        break;
      case "chatbots":
        navigate(`/chatbots`);
        break;
      case "company":
        navigate(`/company`);
        break;
      case "contactUs":
        navigate(`/contactUs`);
        break;
      default:
        break;
    }
  };
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={menuOption}
        onChange={handleNavigation}
      >
        <BottomNavigationAction
          label="B-Screen"
          value="bscreens"
          icon={<ScreenshotMonitorIcon />}
        />
        <BottomNavigationAction
          label="Omnicanal"
          value="omnichannel"
          icon={<CategoryIcon />}
        />
        <BottomNavigationAction
          label="Chatbots"
          value="chatbots"
          icon={<PrecisionManufacturingIcon />}
        />
        <BottomNavigationAction
          label="Nosotros"
          value="company"
          icon={<FeedIcon />}
        />
        <BottomNavigationAction
          label="Contacto"
          value="contactUs"
          icon={<ContactPhoneIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
}
export default MyMenuBotton;
