import React from "react";
import { Box, Stack } from "@mui/material";
import omnichannelImg from "../../img/omnichanel1.png";
import qualityImg from "../../img/quality.png";
import integlarImg from "../../img/integral.png";
import onlineImg from "../../img/online.png";
import { useAppData } from "../../context/AppContext";
import BoxAttributes from "../generic/BoxAttributes";

export default function LayoutOmnichannel() {
  const { isMovile } = useAppData();
  const attributesData = [
    {
      title: "BOTTA BASIC",
      itemsText: [
        "2 agentes",
        "2 Canales básicos + WhatsApp",
        "Mensajes ilimitados",
      ],
    },
    {
      title: "BOTTA PLUS",
      itemsText: [
        "5 agentes",
        "3 Canales básicos + WhatsApp",
        "Mensajes ilimitados",
      ],
    },
    {
      title: "BOTTA PRO",
      itemsText: [
        "10 agentes",
        "4 Canales básicos + WhatsApp",
        "Mensajes ilimitados",
      ],
    },
    {
      title: "BOTTA CUSTOM",
      itemsText: [
        "Agentes y canales personalizado.",
        "Experiencia personalizada.",
      ],
    },
    {
      title: "REPORTES Y BOTS",
      itemsText: [
        "Todos nuestros planes acceso a reportes",
        "integración a Bots",
      ],
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ typography: "h4", fontWeight: "bold" }}>BOTTA Omnicanal</Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
          direction={{ xs: "column", sm: "row", xl: "row", md: "row" }}
        >
          <img src={omnichannelImg} width={380} />
          <Stack spacing={0.5}>
            <Box sx={{ typography: "subtitle1" }}>
              En BOTTA Soft centralizamos en una misma plataforma tus diferentes
              canales de atención al cliente, para que gestiones todos las
              solicitudes que recibes desde whatsApp, redes sociales y otros
              medios en un mismo lugar y con todos tus agentes disponibles,
              permitiendo interacciones coherentes y consistentes con tus
              clientes.
            </Box>
            <Box sx={{ typography: "subtitle1" }}>
              Te brinda un portal Omnicanal y te ofrece alternativas de
              integración a BOTTA Chatbot para optimizar tus respuestas.
            </Box>
            <Box sx={{ typography: "subtitle1" }}>
              BOTTA Soft te brinda soluciones tecnológicas integrales para
              mejorar tu experiencia al cliente.
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Box sx={{ typography: "h4", fontWeight: "bold", marginTop: 1 }}>
        Atributos
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {attributesData?.map((attributesData, i) => (
          <BoxAttributes attributesData={attributesData} />
        ))}
      </Box>
      <Box sx={{ typography: "h4", fontWeight: "bold", marginTop: 2 }}>
        Beneficios
      </Box>
      <Stack
        spacing={0.5}
        direction={{ xs: "column", sm: "row", xl: "row", md: "row" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={qualityImg} />
          <Box sx={{ typography: "h6", fontWeight: "bold", marginTop: 1 }}>
            Calidad
          </Box>
          <Box sx={{ typography: "subtitle1", marginTop: 1 }}>
            Experiencia para tus clientes oportuna, consistente y de calidad.
            Asigna agentes según la necesidad de tus clientes.
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={integlarImg} />
          <Box sx={{ typography: "h6", fontWeight: "bold", marginTop: 1 }}>
            Integral
          </Box>
          <Box sx={{ typography: "subtitle1", marginTop: 1 }}>
            Disponible para integrarse con diversas plataformas de mensajería
            (WhatsApp, Facebook Messenger, entre otras), SMS y más.
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={onlineImg} />
          <Box sx={{ typography: "h6", fontWeight: "bold", marginTop: 1 }}>
            Online
          </Box>
          <Box sx={{ typography: "subtitle1", marginTop: 1 }}>
            Nuestra plataforma tiene acceso disponible las 24 horas online.
            Fideliza clientes y potencia tu imagen empresarial.
          </Box>
        </Box>
      </Stack>
      <Box
        sx={{
          display: "flex",
          typography: "h4",
          marginTop: 6,
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        Diseñamos e implementamos tú chatbot y te brindamos soluciones
        tecnológicas integrales.
      </Box>
    </Box>
  );
}
