import React from "react";
import { Avatar, Box, CardActionArea, Stack } from "@mui/material";
import WhatsappIcon from "../../img/whatsapp_profile_picture.png";
import contactUsImg from "../../img/contact.png";
import bottaSoftImg from "../../img/BottaSoftware.png";
import { useAppData } from "../../context/AppContext";
import BoxAttributes from "../generic/BoxAttributes";

export default function LayoutContactUs() {
  const attributesData = [
    {
      title: "Email",
      itemsText: ["support@botta.com.co"],
    },
    {
      title: "Ubicación",
      itemsText: ["Medellín, Antioquia Colombia"],
    },
  ];
  const handleopenWhatsapp = () => {
    console.log("handleopenWhatsapp: ");
    let url = `https://api.whatsapp.com/send?phone=573016331200&text=Hola_Botta`;
    console.log("url: ", url);
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
          direction="column"
        >
          <img src={contactUsImg} width={380} />
          <Box sx={{ typography: "h4", fontWeight: "bold", marginTop: 2 }}>
            Aprovecha tus canales de comunicación con nosotros.
          </Box>
        </Stack>
      </Box>
      <Box sx={{ typography: "h4", fontWeight: "bold", marginTop: 1 }}></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          flexWrap: "wrap",
        }}
      >
        {attributesData?.map((attributesData, i) => (
          <BoxAttributes attributesData={attributesData} />
        ))}
        <Box sx={{ marginY: 1 }}>
          <CardActionArea onClick={handleopenWhatsapp}>
            <Avatar sx={{ width: 56, height: 56 }} alt="W" src={WhatsappIcon} />
          </CardActionArea>
        </Box>
      </Box>
      <img src={bottaSoftImg} width={"100%"} />
    </Box>
  );
}
