import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Box, Container, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MyMenuBotton from "./MyMenuBotton";
import MyMenuTop from "./MyMenuTop";
import bottaImg from "../../img/BottaSoftCorner.png";

export default function LayoutComponent() {
  const [isMovile, setIsMovile] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const [hasBack, setHasBack] = useState(false);
  const [desktop, setDesktop] = useState(false);

  const handleBack = () => {
    //console.log("handleBack: ");
    navigate(-1);
  };

  const handleHasBack = () => {
    const pathWithBack = [
      "/screens/",
      "/users/edit",
      "/user/addCreditCard",
      "user/creditCards",
      "/invoices",
      "/partners/billing",
    ];
    let findPath = pathWithBack.find((path) => pathname.includes(path));
    //console.log("findPath...: ", findPath);
    if (findPath) {
      setHasBack(true);
    } else {
      setHasBack(false);
    }
  };

  const handleDesktop = () => {
    const pathForDesktop = ["/agenda", "/show"];
    let findPath = pathForDesktop.some((element) => {
      return pathname.endsWith(element);
    });
    //console.log("pathname...: ", pathname);
    //console.log("findPath...: ", findPath);
    if (findPath) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }
  };

  useEffect(() => {
    handleHasBack();
    if (window.innerWidth <= 772) {
      setIsMovile(true);
    } else {
      setIsMovile(false);
    }
    handleDesktop();
  }, [isMovile, pathname]);

  return (
    <Container maxWidth={desktop ? "xl" : "md"}>
      {isMovile ? <img src={bottaImg} width="100%" /> : <MyMenuTop />}
      <Box sx={{ marginTop: 2, paddingBottom: 8 }}>
        {" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {hasBack === true ? (
            <Box>
              <IconButton onClick={handleBack} color="primary">
                <ArrowBackIcon />
              </IconButton>
            </Box>
          ) : null}
        </Box>
        <Outlet />
        {isMovile ? <MyMenuBotton /> : null}
      </Box>
    </Container>
  );
}
