import React from "react";
import { Box, Stack } from "@mui/material";
import chatBotImg from "../../img/chatbot.png";
import allDayImg from "../../img/24_7.png";
import channelsImg from "../../img/channels.png";
import fitImg from "../../img/Fit.png";
import { useAppData } from "../../context/AppContext";
import BoxAttributes from "../generic/BoxAttributes";

export default function LayoutChatBot() {
  const { isMovile } = useAppData();
  const attributesData = [
    {
      title: "Informativo",
      itemsText: ["Brinda información", "Atención a preguntas frecuentes"],
    },
    {
      title: "Pedidos",
      itemsText: ["Guía a tus clientes", "para que ordenen una compra"],
    },
    {
      title: "Agenda",
      itemsText: [
        "Guía a tus clientes",
        "para que agendar una cita",
        "reservas o programa entregas",
      ],
    },
    {
      title: "Encuesta",
      itemsText: ["Interactúa con tus clientes", "con preguntas y respuestas"],
    },
    {
      title: "FIT",
      itemsText: [
        "Adaptamos los asistentes a tú necesidad",
        "cuéntanos cuales son y nosotros",
        "¡Lo hacemos por ti!",
      ],
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ typography: "h4", fontWeight: "bold" }}>BOTTA ChatBot</Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
          direction={{ xs: "column", sm: "row", xl: "row", md: "row" }}
        >
          <img src={chatBotImg} width={380} />
          <Stack spacing={0.5}>
            <Box sx={{ typography: "subtitle1" }}>
              En BOTTA Soft creamos un asistente ChatBot adaptable a las
              necesidades de tu negocio y de tus clientes, para automatizar tus
              conversaciones en plataformas de mensajería, redes sociales y
              páginas web.
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Box sx={{ typography: "h4", fontWeight: "bold", marginTop: 1 }}>
        Asistentes de Chat
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {attributesData?.map((attributesData, i) => (
          <BoxAttributes attributesData={attributesData} />
        ))}
      </Box>
      <Box sx={{ typography: "subtitle1", marginTop: 2 }}>
        Mejora tus procesos operativos, genera eficiencia en tú empresa y
        fideliza clientes con nuestros asistentes.
      </Box>
      <Box sx={{ typography: "h4", fontWeight: "bold", marginTop: 2 }}>
        Beneficios
      </Box>
      <Stack
        spacing={0.5}
        direction={{ xs: "column", sm: "row", xl: "row", md: "row" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={allDayImg} />
          <Box sx={{ typography: "h6", fontWeight: "bold", marginTop: 1 }}>
            24 x 7
          </Box>
          <Box sx={{ typography: "subtitle1", marginTop: 1 }}>
            Los chatbot BOTTA te permiten brindar atención al cliente las 24
            horas del día.
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={channelsImg} />
          <Box sx={{ typography: "h6", fontWeight: "bold", marginTop: 1 }}>
            Canales
          </Box>
          <Box sx={{ typography: "subtitle1", marginTop: 1 }}>
            Disponible para adaptarse en diversas plataformas de mensajería
            (Facebook Messenger, WhatsApp, entre otras) y tú página web.
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={fitImg} />
          <Box sx={{ typography: "h6", fontWeight: "bold", marginTop: 1 }}>
            FIT
          </Box>
          <Box sx={{ typography: "subtitle1", marginTop: 1 }}>
            Se adapta a tu negocio para automatizar tus necesidades de forma
            fácil, protegiendo tú imagen empresarial.
          </Box>
        </Box>
      </Stack>
      <Box
        sx={{
          display: "flex",
          typography: "h4",
          marginTop: 6,
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        Diseñamos e implementamos tú chatbot y te brindamos soluciones
        tecnológicas integrales.
      </Box>
    </Box>
  );
}
