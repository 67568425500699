import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, ButtonGroup } from "@mui/material";
import FeedIcon from "@mui/icons-material/Feed";
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import CategoryIcon from "@mui/icons-material/Category";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";

function MyMenuTop() {
  const navigate = useNavigate();

  const handleNavigation = (event, newValue) => {
    event.preventDefault();
    //console.log("newValue: ", newValue);
    switch (newValue) {
      case "bscreens":
        navigate(`/bscreens`);
        break;
      case "omnichannel":
        navigate(`/omnichannel`);
        break;
      case "chatbots":
        navigate(`/chatbots`);
        break;
      case "company":
        navigate(`/company`);
        break;
      case "contactUs":
        navigate(`/contactUs`);
        break;
      default:
        break;
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        margin: 2,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ButtonGroup variant="text" aria-label="text button group">
        <Button
          color="primary"
          onClick={(event) => handleNavigation(event, "bscreens")}
          startIcon={<ScreenshotMonitorIcon />}
        >
          B-Screen
        </Button>
        <Button
          color="primary"
          onClick={(event) => handleNavigation(event, "omnichannel")}
          startIcon={<CategoryIcon />}
        >
          Omnicanal
        </Button>
        <Button
          color="primary"
          onClick={(event) => handleNavigation(event, "chatbots")}
          startIcon={<PrecisionManufacturingIcon color="primary" />}
        >
          Chatbots
        </Button>
        <Button
          color="primary"
          onClick={(event) => handleNavigation(event, "company")}
          startIcon={<FeedIcon />}
        >
          Nosotros
        </Button>
        <Button
          color="primary"
          onClick={(event) => handleNavigation(event, "contactUs")}
          startIcon={<ContactPhoneIcon />}
        >
          Contacto
        </Button>
      </ButtonGroup>
    </Box>
  );
}
export default MyMenuTop;
